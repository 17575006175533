<template>
  <div>
    <b-card no-body>
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <b-row class="">


          <!-- first name -->

          <b-col class="" cols="12" xl="12">
            <validation-observer ref="EditPersonalInfoForm">
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="12" md="12">
                    <b-form-group
                        label="Mobile"
                        label-for="h-telephone"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Telephone"
                          rules="tel|min_tel|max_tel"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="PhoneIcon"/>
                          </b-input-group-prepend>
                          <b-form-input
                              id="icons-phone"
                              v-model="form.phone_number"
                              placeholder="(+44)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Email -->



                  <b-col cols="12" class="mt-2">
                    <b-row>
                      <div class="bank-details space">
                        Home Address
                      </div>

                      <div class="optional-class">
<!--                        Optional-->
                      </div>
                    </b-row>
                  </b-col>
                  <b-col cols="12" md="12">
                    <div class="mt-1">
                      <b-form-group
                          label="Address 1"
                          label-for="home-address"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules=""
                        >
                          <b-form-input
                              id="home-address"
                              v-model="home_address.address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col cols="12" md="12">
                    <div class="">
                      <b-form-group
                          label="Address 2"
                          label-for="home-address"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules=""
                        >
                          <b-form-input
                              id="home-address"
                              v-model="home_address.address_2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col md="6">
                    <div class="">
                      <b-form-group
                          label="City / Town"
                          label-for="city"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="City"
                            rules="required"
                            :rules="home_address.address?'required':''"
                        >
                          <b-form-input
                              id="city"
                              v-model="home_address.city"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col md="6">
                    <div class="">
                      <b-form-group
                          label="Post Code"
                          label-for="postal-code"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Post Code"
                            :rules="home_address.address?'required':''"
                        >
                          <b-form-input
                              id="postal-code"
                              v-model="home_address.postal_code"
                              placeholder="e.g.PR8 8UW"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col cols="12" class="mt-2">
                    <b-row>
                      <div class="bank-details space">
                        Emergency Contact
                      </div>

                      <div class="optional-class">
<!--                        Optional-->
                      </div>
                    </b-row>
                  </b-col>

                  <b-col cols="12" md="12">
                    <div class="mt-1">
                      <b-form-group
                          label="Emergency contact name"
                          label-for="v-bank-name"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Bank Name"
                            rules=""
                        >
                          <b-form-input
                              id="v-bank-name"
                              v-model="emergency_contact.contact_name"
                              placeholder="Enter Name "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col cols="12" md="12">
                    <div class="">
                      <b-form-group
                          label="Emergency contact number"
                          label-for="account-number"
                      >

                        <validation-provider
                            #default="{ errors }"
                            name="Telephone"
                            rules="tel|min_tel|max_tel"
                        >
                          <b-form-input
                              id="account-number"
                              v-model="emergency_contact.contact_number"
                              placeholder="Enter contact number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                      </b-form-group>
                    </div>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col md="12" class="mt-2">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mr-1"
                        type="submit"
                        variant="primary"
                        @click="profileUpdate()"
                    >
                      Save
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        @click="cancel()"
                    >
                      <span class="text-secondary">
                     Cancel
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>

          </b-col>
        </b-row>
      </b-overlay>
    </b-card>

  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BRow,
  BFormFile,
  BOverlay
} from 'bootstrap-vue'
import profile from '@/apis/modules/profile'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import store from '../../../../store/profile/index'
import fileUploader from "@/constants/fileUploader";
import {imageBaseApiUrl} from '@/constants/config'
import {mapActions, mapGetters} from 'vuex'
import staffBank from '@/apis/modules/staffBank'

export default {
  name: 'profile',
  components: {
    store,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  mixins: [SuccessMessage, ErrorMessages],
  directives: {
    Ripple
  },
  data() {
    return {
      loading: false,
      isHaveProfileImage: false,
      userImage: null,
      changeImageButton: true,
      uploadImage: [],
      userData: {},
      emergency_contact: {
        contact_name:'',
        contact_number:''
      },
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        photo_url: '',
        role: '',
        locum_job_role: ''
      },
      bank_details: {
        name: '',
        account_number: '',
        sort_code: ''
      },
      home_address: {
        address: '',
        address_2:'',
        city: '',
        postal_code: ''
      }
    }
  },
  props: {
    // userData: {
    //   type: Object,
    //   required: true
    // }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  watch: {
    userData(val) {
      this.isHaveProfileImage = val.photo_url
      this.userImage = this.getUserImage()
      this.emergency_contact.contact_name = val.emergency_contacts && val.emergency_contacts[0]?val.emergency_contacts[0].contact_name:''
      this.emergency_contact.contact_number = val.emergency_contacts && val.emergency_contacts[0]?val.emergency_contacts[0].contact_number:''
      this.form.first_name = val.first_name
      this.form.last_name = val.last_name
      this.form.email = val.email
      this.form.phone_number = val.phone_number ? val.phone_number : ''
      this.form.photo_url = val.photo_url
      this.form.locum_job_role = val.locum ? val.locum.job_role.name : ''
      this.home_address.address = val.home_address && val.home_address[0] ? val.home_address[0].address : ''
      this.home_address.address_2 = val.home_address && val.home_address[0] ? val.home_address[0].address_2 : ''
      this.home_address.city = val.home_address && val.home_address[0] ? val.home_address[0].city : ''
      this.home_address.postal_code = val.home_address && val.home_address[0] ? val.home_address[0].postal_code : ''
      this.bank_details.name = val.bank_details.name
      this.bank_details.account_number = val.bank_details.account_number
      this.bank_details.sort_code = val.bank_details.sort_code
    }
  },
  methods: {
    async profileUpdate() {
      if (await this.$refs.EditPersonalInfoForm.validate()) {
        try {
          const payload = {
            phone_number: this.form.phone_number,
          }

          if (this.home_address.address) {
            payload.home_address = this.home_address
          }
          if(this.emergency_contact.contact_name) {
            payload.emergency_contact = this.emergency_contact
          }
          await staffBank.updateContactInfo(this.$route.params.id, payload)
          this.showSuccessMessage('Profile updated')
          this.$emit('updateContactInfo')
          await this.$store.dispatch('autoLogin')
        } catch (e) {
          this.convertAndNotifyError(e)
        }
      }
    },
    cancel() {
      this.$emit('close')
    },
    async loginUser() {
      try {
        this.loading = true
        this.userData = (await profile.getCurrentUser()).data.data
        this.loading = false
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
  },
  mounted() {
    // console.log(this.userData.job_roles)
    this.loginUser()
  }
}
</script>

<style scoped>
.bank-details {
  /*position: absolute;*/
  /*height: 20.19px;*/
  margin-left: 5px;
  left: 2%;
  right: 77.73%;
  /*top: calc(50% - 20.19px/2 + 161.59px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* Dark */
  color: #171822;
}

.optional-class {
  position: absolute;
  height: 21px;
  left: 437px;
  right: 20px;
  margin-top: 3px;
  margin-bottom: 10px;
  /*top: calc(50% - 21px/2 + 162px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  text-align: right;
  /* $dark-grey */
  color: #949494;
}

.space {
  margin-left: 12px;
}
</style>
